<template>
  <div class="dabox">
    <!-- 文字标识 -->
    <div class="dingwei1">
      <p class="wenzi lv lvhuaqing">氯化氢流量</p>
      <p class="wenzi lv lvyixi">氯乙烯</p>
      <p class="wenzi bai lengdongshui5">5 ℃冷冻水</p>
      <p
        class="shu shuzhi1"
        @click="toCompon(2, 'FI06', 'DB1S', 'FI06', '氯化氢流量')"
        @dblclick="Cclick(infoList.DB1S.FI06, 'FI06', 'DB1S', 'FI06')"
      >
        {{ infoList.DB1S.FI06 }} m³/h
      </p>
      <p
        class="shu shuzhi2"
        @click="toCompon(2, 'PT22', 'DB1S', 'PT22_JLT', '压缩机后压力')"
        @dblclick="Cclick(infoList.DB1S.PT22, 'PT22', 'DB1S', 'PT22_JLT')"
      >
        {{ infoList.DB1S.PT22 }} Kpa
      </p>
      <p
        class="shu shuzhi3"
        @click="toCompon(2, 'TE35', 'DB1S', 'TE35_JLT', '压缩机后温度')"
        @dblclick="Cclick(infoList.DB1S.TE35, 'TE35', 'DB1S', 'TE35_JLT')"
      >
        {{ infoList.DB1S.TE35 }} ℃
      </p>
      <p
        class="shu shuzhi4"
        @click="toCompon(2, 'TE01', 'DB1S', 'TE01_JLT', '5℃水送出温度')"
        @dblclick="Cclick(infoList.DB1S.TE01, 'TE01', 'DB1S', 'TE01_JLT')"
      >
        {{ infoList.DB1S.TE01 }} ℃
      </p>
      <p
        class="shu shuzhi5"
        @click="toCompon(2, 'PT01', 'DB1S', 'PT01_JLT', '5℃水送出压力')"
        @dblclick="Cclick(infoList.DB1S.PT01, 'PT01', 'DB1S', 'PT01_JLT')"
      >
        {{ infoList.DB1S.PT01 }} Kpa
      </p>
      <p
        class="shu shuzhi6"
        @click="
          toCompon(2, 'VF14', 'DB1S', 'VF14_JLT', '全凝器5℃水调节阀DCS输出')
        "
        @dblclick="Cclick(infoList.DB1S.VF14, 'VF14', 'DB1S', 'VF14_JLT')"
      >
        {{ infoList.DB1S.VF14 }} %
      </p>
      <p
        class="shu shuzhi7"
        @click="toCompon(2, 'TE03', 'DB1S', 'TE03_JLT', '全凝A下料温度')"
        @dblclick="Cclick(infoList.DB1S.TE03, 'TE03', 'DB1S', 'TE03_JLT')"
      >
        {{ infoList.DB1S.TE03 }} ℃
      </p>
      <p
        class="shu shuzhi8"
        @click="toCompon(2, 'TE04', 'DB1S', 'TE04_JLT', '全凝B下料温度')"
        @dblclick="Cclick(infoList.DB1S.TE04, 'TE04', 'DB1S', 'TE04_JLT')"
      >
        {{ infoList.DB1S.TE04 }} ℃
      </p>
      <p
        class="shu shuzhi9"
        @click="toCompon(2, 'TE05', 'DB1S', 'TE05_JLT', '全凝C下料温度')"
        @dblclick="Cclick(infoList.DB1S.TE05, 'TE05', 'DB1S', 'TE05_JLT')"
      >
        {{ infoList.DB1S.TE05 }} ℃
      </p>
      <p
        class="shu shuzhi10"
        @click="toCompon(2, 'TE06', 'DB1S', 'TE06_JLT', '全凝器气相出口温度')"
        @dblclick="Cclick(infoList.DB1S.TE06, 'TE06', 'DB1S', 'TE06_JLT')"
      >
        {{ infoList.DB1S.TE06 }} ℃
      </p>
      <p
        class="shu shuzhi11"
        @click="toCompon(2, 'TE07', 'DB1S', 'TE07_JLT', '氯乙烯入口温度')"
        @dblclick="Cclick(infoList.DB1S.TE07, 'TE07', 'DB1S', 'TE07_JLT')"
      >
        {{ infoList.DB1S.TE07 }} ℃
      </p>
      <p
        class="shu shuzhi12"
        @click="toCompon(2, 'PT03', 'DB1S', 'PT03_JLT', '氯乙烯入口压力')"
        @dblclick="Cclick(infoList.DB1S.PT03, 'PT03', 'DB1S', 'PT03_JLT')"
      >
        {{ infoList.DB1S.PT03 }} Kpa
      </p>
      <p
        class="shu shuzhi13"
        @click="toCompon(2, 'TE08', 'DB1S', 'TE08_JLT', '氯乙烯出口温度')"
        @dblclick="Cclick(infoList.DB1S.TE08, 'TE08', 'DB1S', 'TE08_JLT')"
      >
        {{ infoList.DB1S.TE08 }} ℃
      </p>
      <p
        class="shu shuzhi14"
        @click="toCompon(2, 'TE12', 'DB1S', 'TE12_JLT', '尾冷气相A出口温度')"
        @dblclick="Cclick(infoList.DB1S.TE12, 'TE12', 'DB1S', 'TE12_JLT')"
      >
        {{ infoList.DB1S.TE12 }} ℃
      </p>
      <p
        class="shu shuzhi15"
        @click="toCompon(2, 'VF15', 'DB1S', 'VF15_JLT', '尾冷A调节阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF15, 'VF15', 'DB1S', 'VF15_JLT')"
      >
        {{ infoList.DB1S.VF15 }} %
      </p>
      <p
        class="shu shuzhi16"
        @click="toCompon(2, 'TE09', 'DB1S', 'TE09_JLT', '尾冷A下料温度')"
        @dblclick="Cclick(infoList.DB1S.TE09, 'TE09', 'DB1S', 'TE09_JLT')"
      >
        {{ infoList.DB1S.TE09 }} ℃
      </p>
      <p
        class="shu shuzhi17"
        @click="toCompon(2, 'PT05', 'DB1S', 'PT05_JLT', '水分离器进口压力')"
        @dblclick="Cclick(infoList.DB1S.PT05, 'PT05', 'DB1S', 'PT05_JLT')"
      >
        {{ infoList.DB1S.PT05 }} Kpa
      </p>
      <p
        class="shu shuzhi18"
        @click="toCompon(2, 'LT01', 'DB1S', 'LT01_JLT', '水分离器液位')"
        @dblclick="Cclick(infoList.DB1S.LT01, 'LT01', 'DB1S', 'LT01_JLT')"
      >
        {{ infoList.DB1S.LT01 }} %
      </p>
      <p
        class="shu shuzhi19"
        @click="toCompon(2, 'ANEV187', 'DB1V', 'ANEV187_JLT', '')"
        @dblclick="
          Cclick(infoList.DB1V.ANEV187, 'ANEV187', 'DB1V', 'ANEV187_JLT')
        "
      >
        {{ infoList.DB1V.ANEV187 }} m³
      </p>
      <p
        class="shu shuzhi20"
        @click="toCompon(2, 'VF04', 'DB1S', 'VF04_JLT', '低沸塔冷凝阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF04, 'VF04', 'DB1S', 'VF04_JLT')"
      >
        {{ infoList.DB1S.VF04 }} %
      </p>
      <p
        class="shu shuzhi21"
        @click="toCompon(2, 'TE22', 'DB1S', 'TE22_JLT', '低沸塔冷凝器温度')"
        @dblclick="Cclick(infoList.DB1S.TE22, 'TE22', 'DB1S', 'TE22_JLT')"
      >
        {{ infoList.DB1S.TE22 }} ℃
      </p>
      <p
        class="shu shuzhi22"
        @click="toCompon(2, 'PT13', 'DB1S', 'PT13_JLT', '低沸塔冷凝器压力')"
        @dblclick="Cclick(infoList.DB1S.PT13, 'PT13', 'DB1S', 'PT13_JLT')"
      >
        {{ infoList.DB1S.PT13 }} Kpa
      </p>
      <p
        class="shu shuzhi51"
        @click="toCompon(2, 'TE13', 'DB1S', 'TE13_JLT', '尾冷气相B出口温度')"
        @dblclick="Cclick(infoList.DB1S.TE13, 'TE13', 'DB1S', 'TE13_JLT')"
      >
        {{ infoList.DB1S.TE13 }} ℃
      </p>
      <p
        class="shu shuzhi52"
        @click="toCompon(2, 'VF16', 'DB1S', 'VF16_JLT', '尾冷B调节阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF16, 'VF16', 'DB1S', 'VF16_JLT')"
      >
        {{ infoList.DB1S.VF16 }} %
      </p>
      <p
        class="shu shuzhi53"
        @click="toCompon(2, 'TE10', 'DB1S', 'TE10_JLT', '尾冷B下料温度')"
        @dblclick="Cclick(infoList.DB1S.TE10, 'TE10', 'DB1S', 'TE10_JLT')"
      >
        {{ infoList.DB1S.TE10 }} ℃
      </p>
      <div
        class="daxiao caozuo1"
        :style="{
          background:
            (infoList.DB1M__p__XK12 && infoList.DB1M__p__XK12.RM == 1) ||
            (infoList.DB1M__p__XK12 && infoList.DB1M__p__XK12.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK12
            ? toDetail(1, 'XK12', 'DB1M__p__XK12', '', 'XK12运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo2"
        :style="{
          background:
            infoList.DB1M__p__MAN12 && infoList.DB1M__p__MAN12.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN12
            ? toCompon(0, 'AV', 'DB1M__p__MAN12', 'MAN12_RM', '手操器12状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo3">12</div>
      <div
        class="daxiao caozuo4"
        :style="{
          background:
            (infoList.DB1M__p__XK13 && infoList.DB1M__p__XK13.RM == 1) ||
            (infoList.DB1M__p__XK13 && infoList.DB1M__p__XK13.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK13
            ? toDetail(1, 'XK13', 'DB1M__p__XK13', '', 'XK13运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo5"
        :style="{
          background:
            infoList.DB1M__p__MAN13 && infoList.DB1M__p__MAN13.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN13
            ? toCompon(0, 'AV', 'DB1M__p__MAN13', 'MAN13_RM', '手操器13状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo6">13</div>
      <div
        class="daxiao caozuo7"
        :style="{
          background:
            infoList.DB1M__p__QK01 && infoList.DB1M__p__QK01.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK01
            ? toDetail(2, 'QK01', 'DB1M__p__QK01', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo8"
        :style="{
          background:
            (infoList.DB1M__p__XK25 && infoList.DB1M__p__XK25.RM == 1) ||
            (infoList.DB1M__p__XK25 && infoList.DB1M__p__XK25.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK25
            ? toDetail(1, 'XK25', 'DB1M__p__XK25', '', 'XK25运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo9"
        :style="{
          background:
            (infoList.DB1M__p__XK02 && infoList.DB1M__p__XK02.RM == 1) ||
            (infoList.DB1M__p__XK02 && infoList.DB1M__p__XK02.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK02
            ? toDetail(1, 'XK02', 'DB1M__p__XK02', '', 'XK02运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo10"
        :style="{
          background:
            infoList.DB1M__p__MAN02 && infoList.DB1M__p__MAN02.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN02
            ? toCompon(0, 'AV', 'DB1M__p__MAN02', 'MAN02_RM', '手操器02状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo11">02</div>
    </div>
    <div class="dingwei2">
      <p class="wenzi xifu">至尾气吸附</p>
      <p class="wenzi lengdongshui35">- 35℃ 冷冻水</p>
      <p class="wenzi bai huishui1">回水</p>
      <p class="wenzi bai huishui2">回水</p>
      <p class="wenzi bai huishui3">回水</p>
      <p class="wenzi huishou">回收气柜</p>
      <p class="wenzi lv danti">单体</p>
      <p
        class="shu shuzhi49"
        @click="toCompon(2, 'PT04', 'DB1S', 'PT04_JLT', '尾排压力')"
        @dblclick="Cclick(infoList.DB1S.PT04, 'PT04', 'DB1S', 'PT04_JLT')"
      >
        {{ infoList.DB1S.PT04 }} Kpa
      </p>
      <p
        class="shu shuzhi50"
        @click="toCompon(2, 'TE14', 'DB1S', 'TE14_JLT', '尾冷气相C出口温度')"
        @dblclick="Cclick(infoList.DB1S.TE14, 'TE14', 'DB1S', 'TE14_JLT')"
      >
        {{ infoList.DB1S.TE14 }} ℃
      </p>
      <p
        class="shu shuzhi54"
        @click="toCompon(2, 'VF13', 'DB1S', 'VF13_JLT', '尾排调节阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF13, 'VF13', 'DB1S', 'VF13_JLT')"
      >
        {{ infoList.DB1S.VF13 }} %
      </p>
      <p
        class="shu shuzhi55"
        @click="toCompon(2, 'TE02', 'DB1S', 'TE02_JLT', '-35℃水送出温度')"
        @dblclick="Cclick(infoList.DB1S.TE02, 'TE02', 'DB1S', 'TE02_JLT')"
      >
        {{ infoList.DB1S.TE02 }} ℃
      </p>
      <p
        class="shu shuzhi56"
        @click="toCompon(2, 'PT02', 'DB1S', 'PT02_JLT', '-35℃水送出压力')"
        @dblclick="Cclick(infoList.DB1S.PT02, 'PT02', 'DB1S', 'PT02_JLT')"
      >
        {{ infoList.DB1S.PT02 }} Kpa
      </p>
      <p
        class="shu shuzhi57"
        @click="toCompon(2, 'VF17', 'DB1S', 'VF17_JLT', '尾冷C调节阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF17, 'VF17', 'DB1S', 'VF17_JLT')"
      >
        {{ infoList.DB1S.VF17 }} %
      </p>
      <p
        class="shu shuzhi58"
        @click="toCompon(2, 'TE11', 'DB1S', 'TE11_JLT', '尾冷C下料温度')"
        @dblclick="Cclick(infoList.DB1S.TE11, 'TE11', 'DB1S', 'TE11_JLT')"
      >
        {{ infoList.DB1S.TE11 }} ℃
      </p>
      <p
        class="shu shuzhi59"
        @click="toCompon(2, 'VF12', 'DB1S', 'VF12_JLT', '5℃水调节阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF12, 'VF12', 'DB1S', 'VF12_JLT')"
      >
        {{ infoList.DB1S.VF12 }} %
      </p>
      <p
        class="shu shuzhi60"
        @click="toCompon(2, 'VF08', 'DB1S', 'VF08_JLT', '高沸塔冷凝阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF08, 'VF08', 'DB1S', 'VF08_JLT')"
      >
        {{ infoList.DB1S.VF08 }} %
      </p>
      <p
        class="shu shuzhi61"
        @click="toCompon(2, 'TE31', 'DB1S', 'TE31_JLT', '高沸塔冷凝器温度')"
        @dblclick="Cclick(infoList.DB1S.TE31, 'TE31', 'DB1S', 'TE31_JLT')"
      >
        {{ infoList.DB1S.TE31 }} ℃
      </p>
      <p
        class="shu shuzhi62"
        @click="toCompon(2, 'PT18', 'DB1S', 'PT18_JLT', '高沸塔冷凝器压力')"
        @dblclick="Cclick(infoList.DB1S.PT18, 'PT18', 'DB1S', 'PT18_JLT')"
      >
        {{ infoList.DB1S.PT18 }} Kpa
      </p>
      <p
        class="shu shuzhi63"
        @click="toCompon(2, 'PT16', 'DB1S', 'PT16_JLT', '高沸塔塔顶压力')"
        @dblclick="Cclick(infoList.DB1S.PT16, 'PT16', 'DB1S', 'PT16_JLT')"
      >
        {{ infoList.DB1S.PT16 }} Kpa
      </p>
      <p
        class="shu shuzhi64"
        @click="toCompon(2, 'TE30', 'DB1S', 'TE30_JLT', '高沸塔塔顶温度')"
        @dblclick="Cclick(infoList.DB1S.TE30, 'TE30', 'DB1S', 'TE30_JLT')"
      >
        {{ infoList.DB1S.TE30 }} ℃
      </p>
      <div
        class="daxiao caozuo12"
        :style="{
          background:
            (infoList.DB1M__p__XK14 && infoList.DB1M__p__XK14.RM == 1) ||
            (infoList.DB1M__p__XK14 && infoList.DB1M__p__XK14.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK14
            ? toDetail(1, 'XK14', 'DB1M__p__XK14', '', 'XK14运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo13"
        :style="{
          background:
            infoList.DB1M__p__MAN14 && infoList.DB1M__p__MAN14.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN14
            ? toCompon(0, 'AV', 'DB1M__p__MAN14', 'MAN14_RM', '手操器14状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo14">14</div>
      <div
        class="daxiao caozuo15"
        :style="{
          background:
            (infoList.DB1M__p__XK11 && infoList.DB1M__p__XK11.RM == 1) ||
            (infoList.DB1M__p__XK11 && infoList.DB1M__p__XK11.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK11
            ? toDetail(1, 'XK11', 'DB1M__p__XK11', '', 'XK11运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo16"
        :style="{
          background:
            infoList.DB1M__p__MAN11 && infoList.DB1M__p__MAN11.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN11
            ? toCompon(0, 'AV', 'DB1M__p__MAN11', 'MAN11_RM', '手操器11状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo17">11</div>
      <div
        class="daxiao caozuo18"
        :style="{
          background:
            (infoList.DB1M__p__XK15 && infoList.DB1M__p__XK15.RM == 1) ||
            (infoList.DB1M__p__XK15 && infoList.DB1M__p__XK15.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK15
            ? toDetail(1, 'XK15', 'DB1M__p__XK15', '', 'XK15运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo19"
        :style="{
          background:
            infoList.DB1M__p__MAN15 && infoList.DB1M__p__MAN15.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN15
            ? toCompon(0, 'AV', 'DB1M__p__MAN15', 'MAN15_RM', '手操器15状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo20">15</div>
      <div
        class="daxiao caozuo21"
        :style="{
          background:
            (infoList.DB1M__p__XK24 && infoList.DB1M__p__XK24.RM == 1) ||
            (infoList.DB1M__p__XK24 && infoList.DB1M__p__XK24.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK24
            ? toDetail(1, 'XK24', 'DB1M__p__XK24', '', 'XK24运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo22"
        :style="{
          background:
            (infoList.DB1M__p__XK10 && infoList.DB1M__p__XK10.RM == 1) ||
            (infoList.DB1M__p__XK10 && infoList.DB1M__p__XK10.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK10
            ? toDetail(1, 'XK10', 'DB1M__p__XK10', '', 'XK10运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo23"
        :style="{
          background:
            infoList.DB1M__p__MAN10 && infoList.DB1M__p__MAN10.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN10
            ? toCompon(0, 'AV', 'DB1M__p__MAN10', 'MAN10_RM', '手操器10状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo24">10</div>
      <div
        class="daxiao caozuo25"
        :style="{
          background:
            infoList.DB1M__p__QK11 && infoList.DB1M__p__QK11.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK11
            ? toDetail(2, 'QK11', 'DB1M__p__QK11', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo26"
        :style="{
          background:
            infoList.DB1M__p__QK07 && infoList.DB1M__p__QK07.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK07
            ? toDetail(2, 'QK07', 'DB1M__p__QK07', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo27"
        :style="{
          background:
            (infoList.DB1M__p__XK06 && infoList.DB1M__p__XK06.RM == 1) ||
            (infoList.DB1M__p__XK06 && infoList.DB1M__p__XK06.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK06
            ? toDetail(1, 'XK06', 'DB1M__p__XK06', '', 'XK06运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo28"
        :style="{
          background:
            (infoList.DB1M__p__XK23 && infoList.DB1M__p__XK23.RM == 1) ||
            (infoList.DB1M__p__XK23 && infoList.DB1M__p__XK23.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK23
            ? toDetail(1, 'XK23', 'DB1M__p__XK23', '', 'XK23运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo29"
        :style="{
          background:
            infoList.DB1M__p__MAN06 && infoList.DB1M__p__MAN06.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN06
            ? toCompon(0, 'AV', 'DB1M__p__MAN06', 'MAN06_RM', '手操器06状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo30">06</div>
    </div>
    <div class="dingwei3">
      <p
        class="shu shuzhi23"
        @click="toCompon(2, 'PT06', 'DB1S', 'PT06_JLT', '固碱顶部压力')"
        @dblclick="Cclick(infoList.DB1S.PT06, 'PT06', 'DB1S', 'PT06_JLT')"
      >
        {{ infoList.DB1S.PT06 }} Kpa
      </p>
      <p
        class="shu shuzhi24"
        @click="toCompon(2, 'PT07', 'DB1S', 'PT07_JLT', '固碱底部压力')"
        @dblclick="Cclick(infoList.DB1S.PT07, 'PT07', 'DB1S', 'PT07_JLT')"
      >
        {{ infoList.DB1S.PT07 }} Kpa
      </p>
      <p
        class="shu shuzhi25"
        @click="
          toCompon(2, 'VF01', 'DB1S', 'VF01_JLT', '固碱C进料调节阀DCS输出')
        "
        @dblclick="Cclick(infoList.DB1S.VF01, 'VF01', 'DB1S', 'VF01_JLT')"
      >
        {{ infoList.DB1S.VF01 }} %
      </p>
      <p
        class="shu shuzhi26"
        @click="toCompon(2, 'PT08', 'DB1S', 'PT08_JLT', '固碱出口压力')"
        @dblclick="Cclick(infoList.DB1S.PT08, 'PT08', 'DB1S', 'PT08_JLT')"
      >
        {{ infoList.DB1S.PT08 }} Kpa
      </p>
      <p
        class="shu shuzhi27"
        @click="toCompon(2, 'FT07', 'DB1S', 'FT07_JLT', '聚合单体流量')"
        @dblclick="Cclick(infoList.DB1S.FT07, 'FT07', 'DB1S', 'FT07_JLT')"
      >
        {{ infoList.DB1S.FT07 }} T/h
      </p>
      <p
        class="shu shuzhi28"
        @click="toCompon(2, 'PT23', 'DB1S', 'PT23_JLT', '单体储槽出口压力')"
        @dblclick="Cclick(infoList.DB1S.PT23, 'PT23', 'DB1S', 'PT23_JLT')"
      >
        {{ infoList.DB1S.PT23 }} Kpa
      </p>
      <p
        class="shu shuzhi29"
        @click="toCompon(2, 'VF03', 'DB1S', 'VF03_JLT', '低沸塔进料阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF03, 'VF03', 'DB1S', 'VF03_JLT')"
      >
        {{ infoList.DB1S.VF03 }} %
      </p>
      <p
        class="shu shuzhi30"
        @click="toCompon(2, 'TE15', 'DB1S', 'TE15_JLT', '低沸塔入口温度')"
        @dblclick="Cclick(infoList.DB1S.TE15, 'TE15', 'DB1S', 'TE15_JLT')"
      >
        {{ infoList.DB1S.TE15 }} ℃
      </p>
      <p
        class="shu shuzhi31"
        @click="toCompon(2, 'FT02', 'DB1S', 'FT02_JLT', '低沸塔进料流量')"
        @dblclick="Cclick(infoList.DB1S.FT02, 'FT02', 'DB1S', 'FT02_JLT')"
      >
        {{ infoList.DB1S.FT02 }} m³/h
      </p>
      <p
        class="shu shuzhi32"
        @click="toCompon(2, 'LT06', 'DB1S', 'LT06_JLT', '水分离器排水储罐液位')"
        @dblclick="Cclick(infoList.DB1S.LT06, 'LT06', 'DB1S', 'LT06_JLT')"
      >
        {{ infoList.DB1S.LT06 }} ℃
      </p>
      <p
        class="shu shuzhi33"
        @click="toCompon(2, 'PT09', 'DB1S', 'PT09_JLT', '低沸塔塔顶压力')"
        @dblclick="Cclick(infoList.DB1S.PT09, 'PT09', 'DB1S', 'PT09_JLT')"
      >
        {{ infoList.DB1S.PT09 }} Kpa
      </p>
      <p
        class="shu shuzhi34"
        @click="toCompon(2, 'TE21', 'DB1S', 'TE21_JLT', '低沸塔塔顶温度')"
        @dblclick="Cclick(infoList.DB1S.TE21, 'TE21', 'DB1S', 'TE21_JLT')"
      >
        {{ infoList.DB1S.TE21 }} ℃
      </p>
      <p
        class="shu shuzhi35"
        @click="toCompon(2, 'LT03', 'DB1S', 'LT03_JLT', '低沸塔回流罐液位')"
        @dblclick="Cclick(infoList.DB1S.LT03, 'LT03', 'DB1S', 'LT03_JLT')"
      >
        {{ infoList.DB1S.LT03 }} %
      </p>
      <p
        class="shu shuzhi36"
        @click="toCompon(2, 'FT03', 'DB1S', 'FT03_JLT', '低沸塔回流流量')"
        @dblclick="Cclick(infoList.DB1S.FT03, 'FT03', 'DB1S', 'FT03_JLT')"
      >
        {{ infoList.DB1S.FT03 }} m³/h
      </p>
      <p
        class="shu shuzhi37"
        @click="toCompon(2, 'TE20', 'DB1S', 'TE20_JLT', '低沸塔提馏段温度2')"
        @dblclick="Cclick(infoList.DB1S.TE20, 'TE20', 'DB1S', 'TE20_JLT')"
      >
        {{ infoList.DB1S.TE20 }} ℃
      </p>
      <p
        class="shu shuzhi38"
        @click="toCompon(2, 'VF06', 'DB1S', 'VF06_JLT', '低沸塔回流阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF06, 'VF06', 'DB1S', 'VF06_JLT')"
      >
        {{ infoList.DB1S.VF06 }} %
      </p>
      <p
        class="shu shuzhi39"
        @click="toCompon(2, 'TED1', 'DB1S', 'TED1_JLT', '低沸塔提馏段温差')"
        @dblclick="Cclick(infoList.DB1S.TED1, 'TED1', 'DB1S', 'TED1_JLT')"
      >
        {{ infoList.DB1S.TED1 }} ℃
      </p>
      <p
        class="shu shuzhi40"
        @click="toCompon(2, 'PT10', 'DB1S', 'PT10_JLT', '低沸塔差压')"
        @dblclick="Cclick(infoList.DB1S.PT10, 'PT10', 'DB1S', 'PT10_JLT')"
      >
        {{ infoList.DB1S.PT10 }} Kpa
      </p>
      <p
        class="shu shuzhi41"
        @click="toCompon(2, 'PT12', 'DB1S', 'PT12_JLT', '低沸塔再沸器压力')"
        @dblclick="Cclick(infoList.DB1S.PT12, 'PT12', 'DB1S', 'PT12_JLT')"
      >
        {{ infoList.DB1S.PT12 }} Kpa
      </p>
      <p
        class="shu shuzhi42"
        @click="toCompon(2, 'TE19', 'DB1S', 'TE19_JLT', '低沸塔再沸器温度')"
        @dblclick="Cclick(infoList.DB1S.TE19, 'TE19', 'DB1S', 'TE19_JLT')"
      >
        {{ infoList.DB1S.TE19 }} ℃
      </p>
      <p
        class="shu shuzhi43"
        @click="toCompon(2, 'PT11', 'DB1S', 'PT11_JLT', '低沸塔塔釜压力')"
        @dblclick="Cclick(infoList.DB1S.PT11, 'PT11', 'DB1S', 'PT11_JLT')"
      >
        {{ infoList.DB1S.PT11 }} Kpa
      </p>
      <p
        class="shu shuzhi44"
        @click="toCompon(2, 'TE17', 'DB1S', 'TE17_JLT', '低沸塔提馏段温度1')"
        @dblclick="Cclick(infoList.DB1S.TE17, 'TE17', 'DB1S', 'TE17_JLT')"
      >
        {{ infoList.DB1S.TE17 }} ℃
      </p>
      <p
        class="shu shuzhi45"
        @click="toCompon(2, 'TE18', 'DB1S', 'TE18_JLT', '低沸塔塔釜温度')"
        @dblclick="Cclick(infoList.DB1S.TE18, 'TE18', 'DB1S', 'TE18_JLT')"
      >
        {{ infoList.DB1S.TE18 }} ℃
      </p>
      <p
        class="shu shuzhi46"
        @click="toCompon(2, 'LT02', 'DB1S', 'LT02_JLT', '低沸塔塔釜液位')"
        @dblclick="Cclick(infoList.DB1S.LT02, 'LT02', 'DB1S', 'LT02_JLT')"
      >
        {{ infoList.DB1S.LT02 }} %
      </p>
      <p
        class="shu shuzhi47"
        @click="toCompon(2, 'VF05', 'DB1S', 'VF05_JLT', '低沸塔热水阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF05, 'VF05', 'DB1S', 'VF05_JLT')"
      >
        {{ infoList.DB1S.VF05 }} %
      </p>
      <p
        class="shu shuzhi48"
        @click="toCompon(2, 'VF07', 'DB1S', 'VF07_JLT', '高沸塔进料阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF07, 'VF07', 'DB1S', 'VF07_JLT')"
      >
        {{ infoList.DB1S.VF07 }} %
      </p>
      <div
        class="daxiao caozuo31"
        :style="{
          background:
            (infoList.DB1M__p__XK01 && infoList.DB1M__p__XK01.RM == 1) ||
            (infoList.DB1M__p__XK01 && infoList.DB1M__p__XK01.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK01
            ? toDetail(1, 'XK01', 'DB1M__p__XK01', '', 'XK01运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo32"
        :style="{
          background:
            (infoList.DB1M__p__MAN01 && infoList.DB1M__p__MAN01.RM == 1) ||
            (infoList.DB1M__p__MAN01 && infoList.DB1M__p__MAN01.RM == 3)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN01
            ? toCompon(0, 'AV', 'DB1M__p__MAN01', 'MAN01_RM', '手操器01状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo33">01</div>
      <div
        class="daxiao caozuo34"
        :style="{
          background:
            (infoList.DB1M__p__XK04 && infoList.DB1M__p__XK04.RM == 1) ||
            (infoList.DB1M__p__XK04 && infoList.DB1M__p__XK04.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK04
            ? toDetail(1, 'XK04', 'DB1M__p__XK04', '', 'XK04运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo35"
        :style="{
          background:
            infoList.DB1M__p__MAN04 && infoList.DB1M__p__MAN04.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN04
            ? toCompon(0, 'AV', 'DB1M__p__MAN04', 'MAN04_RM', '手操器04状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo36">04</div>
      <div
        class="daxiao caozuo37"
        :style="{
          background:
            infoList.DB1M__p__QK02 && infoList.DB1M__p__QK02.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK02
            ? toDetail(2, 'QK02', 'DB1M__p__QK02', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo38"
        :style="{
          background:
            infoList.DB1M__p__QK04 && infoList.DB1M__p__QK04.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK04
            ? toDetail(2, 'QK04', 'DB1M__p__QK04', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo39"
        :style="{
          background:
            (infoList.DB1M__p__XK20 && infoList.DB1M__p__XK20.RM == 1) ||
            (infoList.DB1M__p__XK20 && infoList.DB1M__p__XK20.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK20
            ? toDetail(1, 'XK20', 'DB1M__p__XK20', '', 'XK20运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo40"
        :style="{
          background:
            (infoList.DB1M__p__XK03 && infoList.DB1M__p__XK03.RM == 1) ||
            (infoList.DB1M__p__XK03 && infoList.DB1M__p__XK03.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK03
            ? toDetail(1, 'XK03', 'DB1M__p__XK03', '', 'XK03运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo41"
        :style="{
          background:
            infoList.DB1M__p__MAN03 && infoList.DB1M__p__MAN03.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN03
            ? toCompon(0, 'AV', 'DB1M__p__MAN03', 'MAN03_RM', '手操器03状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo42">03</div>
    </div>
    <div class="dingwei4">
      <p class="wenzi hong huishui4">回水</p>
      <p class="wenzi hong reshui1">热水</p>
      <p class="wenzi hong huishui5">回水</p>
      <p class="wenzi hong reshui2">热水</p>
      <p class="wenzi lv pingheng">平衡管压力</p>
      <p class="wenzi lv lvyixiqigui">氯乙烯气柜</p>
      <p class="wenzi lv yique">乙炔气柜</p>
      <p class="wenzi lv reshui3">热水</p>
      <p
        class="shu shuzhi65"
        @click="toCompon(2, 'LT05', 'DB1S', 'LT05_JLT', '高沸塔回流罐液位')"
        @dblclick="Cclick(infoList.DB1S.LT05, 'LT05', 'DB1S', 'LT05_JLT')"
      >
        {{ infoList.DB1S.LT05 }} %
      </p>
      <p
        class="shu shuzhi66"
        @click="toCompon(2, 'TE25', 'DB1S', 'TE25_JLT', '精馏温度')"
        @dblclick="Cclick(infoList.DB1S.TE25, 'TE25', 'DB1S', 'TE25_JLT')"
      >
        {{ infoList.DB1S.TE25 }} ℃
      </p>
      <p
        class="shu shuzhi67"
        @click="toCompon(2, 'PT15', 'DB1S', 'PT15_JLT', '高沸塔差压')"
        @dblclick="Cclick(infoList.DB1S.PT15, 'PT15', 'DB1S', 'PT15_JLT')"
      >
        {{ infoList.DB1S.PT15 }} Kpa
      </p>
      <p
        class="shu shuzhi68"
        @click="toCompon(2, 'TE28', 'DB1S', 'TE28_JLT', '高沸塔提馏段温度1')"
        @dblclick="Cclick(infoList.DB1S.TE28, 'TE28', 'DB1S', 'TE28_JLT')"
      >
        {{ infoList.DB1S.TE28 }} ℃
      </p>
      <p
        class="shu shuzhi69"
        @click="
          toCompon(2, 'TED3', 'DB1S', 'TED3_JLT', '高沸塔灵敏板与提馏段温差')
        "
        @dblclick="Cclick(infoList.DB1S.TED3, 'TED3', 'DB1S', 'TED3_JLT')"
      >
        {{ infoList.DB1S.TED3 }} ℃
      </p>
      <p
        class="shu shuzhi70"
        @click="toCompon(2, 'TE23', 'DB1S', 'TE23_JLT', '高沸塔提馏段温度2')"
        @dblclick="Cclick(infoList.DB1S.TE23, 'TE23', 'DB1S', 'TE23_JLT')"
      >
        {{ infoList.DB1S.TE23 }} ℃
      </p>
      <p
        class="shu shuzhi71"
        @click="toCompon(2, 'PT14', 'DB1S', 'PT14_JLT', '高沸塔塔釜压力')"
        @dblclick="Cclick(infoList.DB1S.PT14, 'PT14', 'DB1S', 'PT14_JLT')"
      >
        {{ infoList.DB1S.PT14 }} Kpa
      </p>
      <p
        class="shu shuzhi72"
        @click="toCompon(2, 'TE26', 'DB1S', 'TE26_JLT', '高沸塔塔釜温度')"
        @dblclick="Cclick(infoList.DB1S.TE26, 'TE26', 'DB1S', 'TE26_JLT')"
      >
        {{ infoList.DB1S.TE26 }} ℃
      </p>
      <p
        class="shu shuzhi73"
        @click="toCompon(2, 'LT04', 'DB1S', 'LT04_JLT', '高沸塔塔釜液位')"
        @dblclick="Cclick(infoList.DB1S.LT04, 'LT04', 'DB1S', 'LT04_JLT')"
      >
        {{ infoList.DB1S.LT04 }} Kpa
      </p>
      <p
        class="shu shuzhi74"
        @click="toCompon(2, 'FT05', 'DB1S', 'FT05_JLT', '高沸塔回流流量')"
        @dblclick="Cclick(infoList.DB1S.FT05, 'FT05', 'DB1S', 'FT05_JLT')"
      >
        {{ infoList.DB1S.FT05 }} m³/h
      </p>
      <p
        class="shu shuzhi75"
        @click="toCompon(2, 'VF09', 'DB1S', 'VF09_JLT', '高沸塔回流阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF09, 'VF09', 'DB1S', 'VF09_JLT')"
      >
        {{ infoList.DB1S.VF09 }} %
      </p>
      <p
        class="shu shuzhi76"
        @click="
          toCompon(2, 'TED2', 'DB1S', 'TED2_JLT', '高沸塔灵敏板与精馏段温差')
        "
        @dblclick="Cclick(infoList.DB1S.TED2, 'TED2', 'DB1S', 'TED2_JLT')"
      >
        {{ infoList.DB1S.TED2 }} ℃
      </p>
      <p
        class="shu shuzhi77"
        @click="toCompon(2, 'TE29', 'DB1S', 'TE29_JLT', '灵敏板温度')"
        @dblclick="Cclick(infoList.DB1S.TE29, 'TE29', 'DB1S', 'TE29_JLT')"
      >
        {{ infoList.DB1S.TE29 }} ℃
      </p>
      <p
        class="shu shuzhi78"
        @click="toCompon(2, 'PT17', 'DB1S', 'PT17_JLT', '高沸塔再沸器压力')"
        @dblclick="Cclick(infoList.DB1S.PT17, 'PT17', 'DB1S', 'PT17_JLT')"
      >
        {{ infoList.DB1S.PT17 }} Kpa
      </p>
      <p
        class="shu shuzhi79"
        @click="toCompon(2, 'TE27', 'DB1S', 'TE27_JLT', '高沸塔再沸器温度')"
        @dblclick="Cclick(infoList.DB1S.TE27, 'TE27', 'DB1S', 'TE27_JLT')"
      >
        {{ infoList.DB1S.TE27 }} ℃
      </p>
      <p
        class="shu shuzhi80"
        @click="toCompon(2, 'VF10', 'DB1S', 'VF10_JLT', '高沸塔热水阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF10, 'VF10', 'DB1S', 'VF10_JLT')"
      >
        {{ infoList.DB1S.VF10 }} %
      </p>
      <p
        class="shu shuzhi81"
        @click="toCompon(2, 'VF11', 'DB1S', 'VF11_JLT', '高沸塔出料阀DCS输出')"
        @dblclick="Cclick(infoList.DB1S.VF11, 'VF11', 'DB1S', 'VF11_JLT')"
      >
        {{ infoList.DB1S.VF11 }} %
      </p>
      <p
        class="shu shuzhi82"
        @click="toCompon(2, 'TE32', 'DB1S', 'TE32_JLT', '排高沸物温度')"
        @dblclick="Cclick(infoList.DB1S.TE32, 'TE32', 'DB1S', 'TE32_JLT')"
      >
        {{ infoList.DB1S.TE32 }} ℃
      </p>
      <p
        class="shu shuzhi83"
        @click="toCompon(2, 'LT13', 'DB1S', 'LT13_JLT', '高沸物储槽液位')"
        @dblclick="Cclick(infoList.DB1S.LT13, 'LT13', 'DB1S', 'LT13_JLT')"
      >
        {{ infoList.DB1S.LT13 }} %
      </p>
      <p
        class="shu shuzhi84"
        @click="toCompon(2, 'PT21', 'DB1S', 'PT21_JLT', '精馏平衡管压力')"
        @dblclick="Cclick(infoList.DB1S.PT21, 'PT21', 'DB1S', 'PT21_JLT')"
      >
        {{ infoList.DB1S.PT21 }} Kpa
      </p>
      <p
        class="shu shuzhi85"
        @click="toCompon(2, 'LT07', 'DB1S', 'LT07_JLT', '气柜液位1')"
        @dblclick="Cclick(infoList.DB1S.LT07, 'LT07', 'DB1S', 'LT07_JLT')"
      >
        {{ infoList.DB1S.LT07 }} %
      </p>
      <p
        class="shu shuzhi86"
        @click="toCompon(2, 'LT08', 'DB1S', 'LT08_JLT', '气柜液位2')"
        @dblclick="Cclick(infoList.DB1S.LT08, 'LT08', 'DB1S', 'LT08_JLT')"
      >
        {{ infoList.DB1S.LT08 }} %
      </p>
      <p
        class="shu shuzhi87"
        @click="toCompon(2, 'LT09', 'DB1S', 'LT09_JLT', '气柜液位3')"
        @dblclick="Cclick(infoList.DB1S.LT09, 'LT09', 'DB1S', 'LT09_JLT')"
      >
        {{ infoList.DB1S.LT09 }} %
      </p>
      <p
        class="shu shuzhi88"
        @click="toCompon(2, 'LT14', 'DB1S', 'LT10_JLT', '乙炔气柜液位1')"
        @dblclick="Cclick(infoList.DB1S.LT14, 'LT14', 'DB1S', 'LT10_JLT')"
      >
        {{ infoList.DB1S.LT14 }} %
      </p>
      <p
        class="shu shuzhi89"
        @click="toCompon(2, 'LT11', 'DB1S', 'LT11_JLT', '乙炔气柜液位2')"
        @dblclick="Cclick(infoList.DB1S.LT11, 'LT11', 'DB1S', 'LT11_JLT')"
      >
        {{ infoList.DB1S.LT11 }} %
      </p>
      <p
        class="shu shuzhi90"
        @click="toCompon(2, 'LT12', 'DB1S', 'LT12_JLT', '乙炔气柜液位3')"
        @dblclick="Cclick(infoList.DB1S.LT12, 'LT12', 'DB1S', 'LT12_JLT')"
      >
        {{ infoList.DB1S.LT12 }} %
      </p>
      <p
        class="shu shuzhi91"
        @click="toCompon(2, 'PT24', 'DB1S', 'PT24_JLT', '乙炔气柜压力1')"
        @dblclick="Cclick(infoList.DB1S.PT24, 'PT24', 'DB1S', 'PT24_JLT')"
      >
        {{ infoList.DB1S.PT24 }} Kpa
      </p>
      <p
        class="shu shuzhi92"
        @click="toCompon(2, 'PT25', 'DB1S', 'PT25_JLT', '乙炔气柜压力2')"
        @dblclick="Cclick(infoList.DB1S.PT25, 'PT25', 'DB1S', 'PT25_JLT')"
      >
        {{ infoList.DB1S.PT25 }} Kpa
      </p>
      <p
        class="shu shuzhi93"
        @click="toCompon(2, 'PT26', 'DB1S', 'PT26_JLT', '乙炔气柜压力3')"
        @dblclick="Cclick(infoList.DB1S.PT26, 'PT26', 'DB1S', 'PT26_JLT')"
      >
        {{ infoList.DB1S.PT26 }} Kpa
      </p>
      <p
        class="shu shuzhi94"
        @click="toCompon(2, 'TE33', 'DB1S', 'TE33_JLT', '热水塔1温度')"
        @dblclick="Cclick(infoList.DB1S.TE33, 'TE33', 'DB1S', 'TE33_JLT')"
      >
        {{ infoList.DB1S.TE33 }} ℃
      </p>
      <p
        class="shu shuzhi95"
        @click="toCompon(2, 'TE34', 'DB1S', 'TE34_JLT', '热水塔2温度')"
        @dblclick="Cclick(infoList.DB1S.TE34, 'TE34', 'DB1S', 'TE34_JLT')"
      >
        {{ infoList.DB1S.TE34 }} ℃
      </p>
      <div
        class="daxiao caozuo43"
        :style="{
          background:
            (infoList.DB1M__p__XK05 && infoList.DB1M__p__XK05.RM == 1) ||
            (infoList.DB1M__p__XK05 && infoList.DB1M__p__XK05.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK05
            ? toDetail(1, 'XK05', 'DB1M__p__XK05', '', 'XK05运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo44"
        :style="{
          background:
            infoList.DB1M__p__MAN05 && infoList.DB1M__p__MAN05.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN05
            ? toCompon(0, 'AV', 'DB1M__p__MAN05', 'MAN05_RM', '手操器05状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo45">05</div>
      <div
        class="daxiao caozuo46"
        :style="{
          background:
            (infoList.DB1M__p__XK07 && infoList.DB1M__p__XK07.RM == 1) ||
            (infoList.DB1M__p__XK07 && infoList.DB1M__p__XK07.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK07
            ? toDetail(1, 'XK07', 'DB1M__p__XK07', '', 'XK07运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo47"
        :style="{
          background:
            infoList.DB1M__p__MAN07 && infoList.DB1M__p__MAN07.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN07
            ? toCompon(0, 'AV', 'DB1M__p__MAN07', 'MAN07_RM', '手操器07状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo48">07</div>
      <div
        class="daxiao caozuo49"
        :style="{
          background:
            infoList.DB1M__p__QK15 && infoList.DB1M__p__QK15.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK15
            ? toDetail(2, 'QK15', 'DB1M__p__QK15', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo50"
        :style="{
          background:
            infoList.DB1M__p__QK09 && infoList.DB1M__p__QK09.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK09
            ? toDetail(2, 'QK09', 'DB1M__p__QK09', '', 'QK正反作用')
            : ''
        "
      >
        Q
      </div>
      <div
        class="daxiao caozuo51"
        :style="{
          background:
            (infoList.DB1M__p__XK17 && infoList.DB1M__p__XK17.RM == 1) ||
            (infoList.DB1M__p__XK17 && infoList.DB1M__p__XK17.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK17
            ? toDetail(1, 'XK17', 'DB1M__p__XK17', '', 'XK17运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo52"
        :style="{
          background:
            (infoList.DB1M__p__XK08 && infoList.DB1M__p__XK08.RM == 1) ||
            (infoList.DB1M__p__XK08 && infoList.DB1M__p__XK08.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK08
            ? toDetail(1, 'XK08', 'DB1M__p__XK08', '', 'XK08运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="daxiao caozuo53"
        :style="{
          background:
            infoList.DB1M__p__MAN08 && infoList.DB1M__p__MAN08.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN08
            ? toCompon(0, 'AV', 'DB1M__p__MAN08', 'MAN08_RM', '手操器08状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo54">08</div>
      <div
        class="daxiao caozuo55"
        :style="{
          background:
            infoList.DB1M__p__MAN09 && infoList.DB1M__p__MAN09.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN09
            ? toCompon(0, 'AV', 'DB1M__p__MAN09', 'MAN09_RM', '手操器09状态')
            : ''
        "
      >
        A
      </div>
      <div class="daxiao yanse caozuo56">09</div>
      <!-- <p class="shu shuzhi96"></p>
      <p class="shu shuzhi"></p>
      <p class="shu shuzhi"></p>
      <p class="shu shuzhi"></p> -->
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Jlindex",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  // filters: {
  //   // 阀门红绿灯
  //   typeFifter: (val) => {
  //     if (val < 5) {
  //       return require("@/assets/images/截止阀.png");
  //     } else if (val >= 5) {
  //       return require("@/assets/images/截止阀2.png");
  //     }
  //   },
  //   //釜内红绿灯
  //   tyFifter: (val) => {
  //     if (val <= 10) {
  //       return require("@/assets/images/关闭.png");
  //     } else if (val > 10) {
  //       return require("@/assets/images/开始.png");
  //     }
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 90%;
  height: 81vh;
  margin-left: 3vw;
  margin-top: 2vh;
  background-image: url("~@/assets/images/君正精馏 .png");
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  .dingwei1,
  .dingwei2,
  .dingwei3,
  .dingwei4 {
    position: relative;
    font-size: 0.9vw;
    width: 50%;
    height: 50%;
    .wenzi {
      position: absolute;
    }
    .lv {
      color: #029b81;
    }
    .bai {
      color: #f4ffff;
    }
    .hong {
      color: #c70000;
      font-size: 0.8vw;
    }
    .shu {
      color: #f4ffff;
      font-size: 0.7vw;
      position: absolute;
      z-index: 10; /* 提高层级 */
    }
    .daxiao {
      position: absolute;
      width: 1vw;
      height: 2vh;
      color: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #15eafd;
      z-index: 11; /* 提高层级 */
    }
    .yanse {
      color: #0040a1;
      background-color: #15eafd;
    }
    .lvhuaqing {
      top: -2vh;
      left: 4vw;
    }
    .lvyixi {
      top: 3vh;
      left: 3vw;
    }
    .lengdongshui5 {
      top: 12vh;
      left: 0vw;
    }
    .xifu {
      top: 1.2vh;
      left: 21vw;
      color: #a4a223;
    }
    .lengdongshui35 {
      top: 5.5vh;
      left: 21vw;
      color: #3978c5;
    }
    .huishui1 {
      top: 23.9vh;
      left: 4vw;
    }
    .huishui2 {
      top: 14.6vh;
      left: 26.5vw;
    }
    .huishui3 {
      top: 9.8vh;
      right: -2.3vw;
    }
    .huishui4 {
      bottom: 5.2vh;
      left: -0.5vw;
    }
    .huishui5 {
      bottom: 6.3vh;
      right: 18.5vw;
    }
    .reshui1 {
      bottom: 7.5vh;
      left: -0.5vw;
    }
    .reshui2 {
      bottom: 8.6vh;
      right: 18.5vw;
    }
    .huishou {
      top: 1.6vh;
      right: -2vw;
      color: #00dddd;
    }
    .danti {
      bottom: 11vh;
      right: -2vw;
    }
    .pingheng {
      top: -10.5vh;
      right: 5vw;
    }
    .lvyixiqigui {
      top: -6.3vh;
      right: 5vw;
    }
    .yique {
      top: 2vh;
      right: 5vw;
    }
    .reshui3 {
      top: 18.5vh;
      right: 5vw;
    }
    .shuzhi1 {
      top: -1.7vh;
      left: 9vw;
    }
    .shuzhi2 {
      top: 2vh;
      left: 7vw;
    }
    .shuzhi3 {
      top: 4.5vh;
      left: 7vw;
    }
    .shuzhi4 {
      top: 16vh;
      left: 0vw;
    }
    .shuzhi5 {
      top: 18vh;
      left: 0vw;
    }
    .shuzhi6 {
      top: 16vh;
      left: 7vw;
    }
    .shuzhi7 {
      top: 17vh;
      left: 12.5vw;
    }
    .shuzhi8 {
      top: 17vh;
      left: 18.8vw;
    }
    .shuzhi9 {
      top: 17vh;
      left: 25vw;
    }
    .shuzhi10 {
      top: 4.8vh;
      left: 29vw;
    }
    .shuzhi11 {
      top: 12vh;
      left: 29vw;
    }
    .shuzhi12 {
      top: 14vh;
      left: 29vw;
    }
    .shuzhi13 {
      top: 18vh;
      left: 28vw;
    }
    .shuzhi14 {
      top: 3vh;
      right: 7.5vw;
    }
    .shuzhi15 {
      top: 12vh;
      right: 5.7vw;
    }
    .shuzhi16 {
      top: 18vh;
      right: 7.5vw;
    }
    .shuzhi51 {
      top: 3vh;
      right: -2vw;
    }
    .shuzhi52 {
      top: 12vh;
      right: -4.2vw;
    }
    .shuzhi53 {
      top: 18vh;
      right: -1.5vw;
    }
    .shuzhi17 {
      bottom: 10vh;
      left: 11.5vw;
    }
    .shuzhi18 {
      bottom: 8vh;
      left: 12.5vw;
    }
    .shuzhi19 {
      bottom: 7vh;
      left: 17.5vw;
    }
    .shuzhi20 {
      bottom: 12vh;
      right: 6.7vw;
    }
    .shuzhi21 {
      bottom: 10vh;
      right: -2.7vw;
    }
    .shuzhi22 {
      bottom: 8vh;
      right: -3.2vw;
    }
    .shuzhi49 {
      top: 0vh;
      left: 12vw;
    }
    .shuzhi50 {
      top: 3vh;
      left: 8.4vw;
    }
    .shuzhi54 {
      top: 3vh;
      left: 16.4vw;
    }
    .shuzhi55 {
      top: 7vh;
      left: 16.4vw;
    }
    .shuzhi56 {
      top: 8.5vh;
      left: 16.4vw;
    }
    .shuzhi57 {
      top: 12vh;
      left: 10.5vw;
    }
    .shuzhi58 {
      top: 18vh;
      left: 8.5vw;
    }
    .shuzhi59 {
      top: 12vh;
      left: 24.7vw;
    }
    .shuzhi60 {
      top: 16.5vh;
      left: 17.9vw;
    }
    .shuzhi61 {
      top:21vh;
      right: 16.5vw;
    }
    .shuzhi62 {
      top: 22.5vh;
      right: 16vw;
    }
    .shuzhi63 {
      bottom: 13vh;
      left: 9.4vw;
    }
    .shuzhi64 {
      bottom: 13vh;
      left: 13.5vw;
    }
    .shuzhi23 {
      top: -5vh;
      left: 22.2vw;
    }
    .shuzhi24 {
      top: -2vh;
      left: 22.2vw;
    }
    .shuzhi25 {
      top: 1vh;
      left: 15.6vw;
    }
    .shuzhi26 {
      top: 1.5vh;
      left: 19.6vw;
    }
    .shuzhi27 {
      bottom: 21vh;
      left: 0vw;
    }
    .shuzhi28 {
      bottom: 19vh;
      left: 0vw;
    }
    .shuzhi29 {
      top: 9vh;
      left: 9.7vw;
    }
    .shuzhi30 {
      top: 6vh;
      right: 17vw;
    }
    .shuzhi31 {
      top: 8.5vh;
      right: 16.4vw;
    }
    .shuzhi32 {
      bottom: 15vh;
      left: 15.4vw;
      color: #000;
    }
    .shuzhi33 {
      top: -4.5vh;
      right: 12.9vw;
    }
    .shuzhi34 {
      top: -4.5vh;
      right: 9vw;
    }
    .shuzhi35 {
      top: -4.5vh;
      right: -3.8vw;
    }
    .shuzhi36 {
      top: 4.5vh;
      right: 6vw;
    }
    .shuzhi37 {
      top: 7vh;
      right: 6.5vw;
    }
    .shuzhi38 {
      top: 7.5vh;
      right: 2.5vw;
    }
    .shuzhi39 {
      bottom: 21vh;
      right: 7.8vw;
    }
    .shuzhi40 {
      bottom: 19vh;
      right: 14.6vw;
    }
    .shuzhi41 {
      bottom: 13.5vh;
      right: 6.5vw;
    }
    .shuzhi42 {
      bottom: 11.5vh;
      right: 7vw;
    }
    .shuzhi43 {
      bottom: 5.5vh;
      right: 18.5vw;
    }
    .shuzhi44 {
      bottom: 9.5vh;
      right: 14.5vw;
    }
    .shuzhi45 {
      bottom: 5.5vh;
      right: 14.5vw;
    }
    .shuzhi46 {
      bottom: 3.5vh;
      right: 14.5vw;
    }
    .shuzhi47 {
      bottom: 6.4vh;
      right: 2.3vw;
    }
    .shuzhi48 {
      bottom: 0vh;
      right: -1vw;
    }
    .shuzhi65 {
      top: -10.5vh;
      right: 14.6vw;
    }
    .shuzhi66 {
      top: -4.5vh;
      left: 8vw;
    }
    .shuzhi67 {
      top: 14vh;
      left: 7vw;
    }
    .shuzhi68 {
      top: 16vh;
      left: 7.3vw;
    }
    .shuzhi69 {
      top: 21vh;
      left: 8vw;
    }
    .shuzhi70 {
      bottom: 11vh;
      left: 8vw;
    }
    .shuzhi71 {
      bottom: 9vh;
      left: 7vw;
    }
    .shuzhi72 {
      bottom: 7vh;
      left: 8vw;
    }
    .shuzhi73 {
      bottom: 5vh;
      left: 7vw;
    }
    .shuzhi74 {
      top: -8vh;
      left: 15vw;
    }
    .shuzhi75 {
      top: -5vh;
      left: 21vw;
    }
    .shuzhi76 {
      top: 0vh;
      left: 15vw;
    }
    .shuzhi77 {
      top: 8vh;
      left: 15vw;
    }
    .shuzhi78 {
      bottom: 15vh;
      left: 15vw;
    }
    .shuzhi79 {
      bottom: 13vh;
      left: 15vw;
    }
    .shuzhi80 {
      bottom: 7.5vh;
      left: 21vw;
    }
    .shuzhi81 {
      bottom: 0vh;
      right: 14.5vw;
    }
    .shuzhi82 {
      bottom: 2.8vh;
      right: 11vw;
    }
    .shuzhi83 {
      bottom: 0vh;
      right: -0.5vw;
    }
    .shuzhi84 {
      top: -10vh;
      right: 0vw;
    }
    .shuzhi85 {
      top: -6vh;
      right: 0vw;
    }
    .shuzhi86 {
      top: -3.3vh;
      right: 0vw;
    }
    .shuzhi87 {
      top: -0.6vh;
      right: 0vw;
    }
    .shuzhi88 {
      top: 2.3vh;
      right: 0vw;
    }
    .shuzhi89 {
      top: 5vh;
      right: 0vw;
    }
    .shuzhi90 {
      top: 7.8vh;
      right: 0vw;
    }
    .shuzhi91 {
      top: 10.6vh;
      right: 0vw;
    }
    .shuzhi92 {
      top: 13.4vh;
      right: 0vw;
    }
    .shuzhi93 {
      top: 16.2vh;
      right: 0vw;
    }
    .shuzhi94 {
      top: 19vh;
      right: 0vw;
    }
    .shuzhi95 {
      top: 21.8vh;
      right: 0vw;
    }
    .caozuo1 {
      top: 10.5vh;
      left: 6vw;
    }
    .caozuo2 {
     top: 10.5vh;
      left: 7.5vw;
    }
    .caozuo3 {
      top: 10.5vh;
      left: 9vw;
    }
    .caozuo4 {
      top: 9.5vh;
      right: 5.8vw;
    }
    .caozuo5 {
      top: 9.5vh;
      right: 4.3vw;
    }
    .caozuo6 {
      top: 8vh;
      right: 3.1vw;
    }
    .caozuo7 {
      bottom: 7.2vh;
      right: 10.8vw;
    }
    .caozuo8 {
      bottom: 7.2vh;
      right: 9.3vw;
    }
    .caozuo9 {
      bottom: 10vh;
      right: 9.3vw;
    }
    .caozuo10 {
      bottom: 10vh;
      right: 7.8vw;
    }
    .caozuo11 {
      bottom: 10vh;
      right: 6.3vw;
    }
    .caozuo12 {
      top: 9.5vh;
      left: 2.5vw;
    }
    .caozuo13 {
      top: 9.5vh;
      left: 4vw;
    }
    .caozuo14 {
      top: 8vh;
      left: 5.3vw;
    }
    .caozuo15 {
      top: -1vh;
      left: 19vw;
    }
    .caozuo16 {
      top: -1vh;
      left: 20.5vw;
    }
    .caozuo17 {
      top: -1vh;
      left: 22vw;
    }
    .caozuo18 {
      top: 9.2vh;
      left: 12vw;
    }
    .caozuo19 {
      top: 9.2vh;
      left: 13.5vw;
    }
    .caozuo20 {
      top: 12vh;
      left: 14vw;
    }
    .caozuo21 {
      top: 5vh;
      right: 16vw;
    }
    .caozuo22 {
      top: 8vh;
      right: 16vw;
    }
    .caozuo23 {
      top: 8vh;
      right: 14.5vw;
    }
    .caozuo24 {
      top: 8vh;
      right: 13vw;
    }
    .caozuo25 {
      top: 19vh;
      left: 15.5vw;
    }
    .caozuo26 {
      top: 21.8vh;
      left: 15.5vw;
    }
    .caozuo27 {
      top: 19vh;
      left: 17vw;
    }
    .caozuo28 {
      top: 21.8vh;
      left: 17vw;
    }
    .caozuo29 {
      top:19vh;
      left: 18.5vw;
    }
    .caozuo30 {
      top: 21.8vh;
      left: 18.5vw;
    }
    .caozuo31 {
      top: 3vh;
      left: 7.5vw;
    }
    .caozuo32 {
      top: 3vh;
      left: 9vw;
    }
    .caozuo33 {
      top: 0vh;
      left: 7.5vw;
    }
    .caozuo34 {
      top: 1.5vh;
      right: 6.5vw;
    }
    .caozuo35 {
      top: 1.5vh;
      right: 5vw;
    }
    .caozuo36 {
      top: 1.5vh;
      right: 3.5vw;
    }
    .caozuo37 {
      bottom: 19vh;
      right: 2.5vw;
    }
    .caozuo38 {
      bottom: 16vh;
      right: 1vw;
    }
    .caozuo39 {
      bottom: 16vh;
      right: 2.5vw;
    }
    .caozuo40 {
      bottom: 13vh;
      right: 2.5vw;
    }
    .caozuo41 {
      bottom: 13vh;
      right: 1vw;
    }
    .caozuo42 {
      bottom: 13vh;
      right: -0.5vw;
    }
    .caozuo43 {
      bottom: 0vh;
      left: 3vw;
    }
    .caozuo44 {
      bottom: 0vh;
      left: 4.5vw;
    }
    .caozuo45 {
      bottom: 0vh;
      left: 6vw;
    }
    .caozuo46 {
      top: -2vh;
      left: 23vw;
    }
    .caozuo47 {
      top: -2vh;
      left: 24.5vw;
    }
    .caozuo48 {
      top: -2vh;
      left: 26vw;
    }
    .caozuo49 {
      bottom: 20vh;
      right: 21.5vw;
    }
    .caozuo50 {
      bottom: 17vh;
      right: 20vw;
    }
    .caozuo51 {
      bottom: 17vh;
      right: 21.5vw;
    }
    .caozuo52 {
      bottom: 14vh;
      right: 21.5vw;
    }
    .caozuo53 {
      bottom: 14vh;
      right: 20vw;
    }
    .caozuo54 {
      bottom: 14vh;
      right: 18.5vw;
    }
    .caozuo55 {
      bottom: 7vh;
      right: 15vw;
    }
    .caozuo56 {
      bottom: 7vh;
      right: 13.5vw;
    }
  }
}
</style>