import Config from "@/utils/config"
import { getIndexInfo } from "@/api/index/index"
export default {
    data() {
        return {
            timer: null,
            info: {},
            isComShow: false,
            authInfo: [],
            UserInfo: [],
            isShow: false,
            spotArr: [],
            grouptime: null,
            Manualname: "",
            Manualnode: "",
            ManualAname: "",
            Manualtitname: "",
            Firstcontrolname: "",
            Firstcontrolnode: "",
            Firstcontroltitname: "",
        }
    },
    created() {
        this.deviceType = JSON.parse(localStorage.getItem("deviceType"))
        this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
        this.authInfo = JSON.parse(localStorage.getItem("autharr"))
        this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)

    },
    methods: {
        // 打开先控多窗口
        toDetail(key, name, nodename, Aname, titname) {
            if (!this.authInfo[1]) {
                let str = this.authInfo[0]
                // 判断是否为调试还是查看权限
                if (str.charAt(str.length - 1) == "r") {
                    this.$message.error('暂无调试权限');
                }
            }else{
				let pathname=""
				switch(key){
					case 1:
					pathname = 'firstcontol'
					break
					case 2:
				    pathname = 'rqkfirstcontol1'
					break
					case 3:
				    pathname = 'Rfirstcontol'
					break
				}
				const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
				window.open(newurl.href,'_blank')
			}
        },
        // 打开组件
        toCompon(key, name, nodename, Aname, titname) {
            if (!this.authInfo[1] && key != 2) {
                let str = this.authInfo[0]
                // 判断是否为调试还是查看权限
                if (str.charAt(str.length - 1) == "r") {
                    this.$message.error('暂无调试权限');
                }
            } else {
                this.isIndex = key
                this.Aname = Aname
                this.historyname = name,
                    this.nodename = nodename
                clearTimeout(this.grouptime);
                this.grouptime = setTimeout(() => {
                    this.maskbok = true
                    switch (key) {
                        case 0:
                            return this.isMshow = true, this.Manualname = name, this.Manualnode = nodename, this.ManualAname = Aname, this.Manualtitname = titname
                        case 1:
                            return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode = nodename, this.Firstcontroltitname = titname
                        case 2:
                            return this.isHshow = true, this.chName = titname, this.maskbok = false
                    }
                })
            }
        },
        isClose(val, val1) {
            this.maskbok = false
            switch (val) {
                case 'Manual':
                    return this.isMshow = val1
                case 'Historical':
                    return this.isHshow = val1
            }
        },
        // 监听滚动条
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

            if (scrollTop >= 680) {
                this.isShow = true
            } else {
                this.isShow = false
            }
        },
    },
    destroyed() {
        // 离开该页面需要移除这个监听的事件，不然会报错
        window.removeEventListener("scroll", this.handleScroll)
    },
}